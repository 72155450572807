// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicTestimonial-module--__wab_img-spacer--3eW1G";
export var avatar = "PlasmicTestimonial-module--avatar--j84fQ";
export var freeBox__b9Nie = "PlasmicTestimonial-module--freeBox__b9Nie--zZ-Tr";
export var freeBox__r0Ckd = "PlasmicTestimonial-module--freeBox__r0Ckd--cC-+H";
export var img = "PlasmicTestimonial-module--img--LLYpw";
export var root = "PlasmicTestimonial-module--root--b0trQ";
export var svg = "PlasmicTestimonial-module--svg--HsaxC";
export var text__dqyHr = "PlasmicTestimonial-module--text__dqyHr--KLIlA";
export var text__nbqn7 = "PlasmicTestimonial-module--text__nbqn7--T5Qmj";
export var text__xXfQj = "PlasmicTestimonial-module--text__xXfQj--RKBpW";