// extracted by mini-css-extract-plugin
export var freeBox__hJyQ = "PlasmicFeatureCard-module--freeBox__hJyQ--1CiZG";
export var freeBox__keHwq = "PlasmicFeatureCard-module--freeBox__keHwq--VaWcT";
export var freeBox__qbCsz = "PlasmicFeatureCard-module--freeBox__qbCsz--BeXo+";
export var freeBox__yZq9W = "PlasmicFeatureCard-module--freeBox__yZq9W--xpbBB";
export var freeBoxlong__hJyQd7AYz = "PlasmicFeatureCard-module--freeBoxlong__hJyQd7AYz--Xrsue";
export var freeBoxlong__keHwqd7AYz = "PlasmicFeatureCard-module--freeBoxlong__keHwqd7AYz--VjeJA";
export var freeBoxlong__yZq9Wd7AYz = "PlasmicFeatureCard-module--freeBoxlong__yZq9Wd7AYz--R5h9v";
export var root = "PlasmicFeatureCard-module--root--Eqihd";
export var rootlong = "PlasmicFeatureCard-module--rootlong--c-VTr";
export var slotTargetDescription = "PlasmicFeatureCard-module--slotTargetDescription--JPlNt";
export var slotTargetDescriptionlong = "PlasmicFeatureCard-module--slotTargetDescriptionlong--74-l5";
export var slotTargetTitle = "PlasmicFeatureCard-module--slotTargetTitle--i6I9w";
export var svg___6Uzew = "PlasmicFeatureCard-module--svg___6Uzew--dlofv";
export var svg__u59Ux = "PlasmicFeatureCard-module--svg__u59Ux--hOhSt";